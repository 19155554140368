function extractRelativeUrl(url: URL): string {
  return url.toString().substr(url.origin.length);
}

function fullUrl(relativeUrl: string): URL {
  return new URL(relativeUrl, window.location.origin);
}

function transformRelativeUrl(relativeUrl: string, txm: (url: URL) => void): string {
  const url = fullUrl(relativeUrl);
  txm(url);
  return extractRelativeUrl(url);
}

function addQueryParam(relativeUrl: string, param: string, value: string): string {
  return transformRelativeUrl(relativeUrl, (u) => u.searchParams.set(param, value));
}

function removeQueryParam(relativeUrl: string, param: string): string {
  return transformRelativeUrl(relativeUrl, (u) => u.searchParams.delete(param));
}

function preserveParam(relativeUrl: string, param: string): string {
  const currentUrl = new URL(window.location.href);
  if (!currentUrl.searchParams.has(param)) {
    return relativeUrl;
  }
  const paramValue = currentUrl.searchParams.get(param);
  return transformRelativeUrl(relativeUrl, (u) => u.searchParams.set(param, paramValue));
}

function currentRelativeUrl(): string {
  const url = new URL(window.location.href);
  return extractRelativeUrl(url);
}

function getOrigin() {
  return window.location.origin;
}

function getUrlParamRecord(): Record<string, string> {
  return Object.fromEntries(new URLSearchParams(window.location.search));
}

function isHrefSameHost(href: string): boolean {
  return new URL(href, window.location.origin).origin === window.location.origin;
}

export {
  extractRelativeUrl,
  fullUrl,
  transformRelativeUrl,
  addQueryParam,
  removeQueryParam,
  preserveParam,
  currentRelativeUrl,
  getOrigin,
  getUrlParamRecord,
  isHrefSameHost,
};
