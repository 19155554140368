// unfetch is a tiny 500b fetch polyfill
// more info - https://www.npmjs.com/package/unfetch
import { transformRelativeUrl } from '@/utils/url';
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import 'unfetch/polyfill';

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        imported_git_repository: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'ImportedGitRepositoryType',
              id: args.id,
            });
          },
        },
        range_model_metrics: {
          // This allows different model metrics to be stored together in the cache
          merge: true,
        },
        instant_model_metrics: {
          // This allows different model metrics to be stored together in the cache
          merge: true,
        },
        oracle_stable_environment: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'OracleStableEnvironmentType',
              id: args.id,
            });
          },
        },
        chain_stable_environment: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'ChainStableEnvironmentType',
              id: args.id,
            });
          },
        },
      },
    },
    PretrainedModelType: {
      keyFields: ['name'],
    },
  },
});

// Default HttpLink for non-batched queries
const httpLink = new HttpLink({
  uri: (op) =>
    transformRelativeUrl('/graphql/', (url) => {
      url.searchParams.set('opName', op.operationName);
      if (op.getContext().useRootUser) {
        url.searchParams.set('useRootUser', 'true');
      }
    }),
});

const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
  defaultOptions: {
    mutate: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
  },
});

export { cache };
export default apolloClient;
