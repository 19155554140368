/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { UserFragmentDoc } from './fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserQuery = { __typename?: 'QueryPrediction', user: { __typename?: 'UserType', id: string, status: Types.ApprovalStatus | null | undefined, username: string, name: string | null | undefined, email: string | null | undefined, created: any, companyName: string | null | undefined, firstName: string | null | undefined, lastName: string | null | undefined, profileUse: string | null | undefined, linkedinProfile: string | null | undefined, hasCompletedProfile: boolean, workspaceName: string | null | undefined, roleName: string | null | undefined, applicationsAndDataEnabled: boolean | null | undefined, showAdminUI: boolean | null | undefined } | null | undefined };


export const UserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"User"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserFragment"}}]}}]}},...UserFragmentDoc.definitions]} as unknown as DocumentNode<UserQuery, UserQueryVariables>;
export const namedOperations = {
  Query: {
    User: 'User'
  }
}

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export function refetchUserQuery(variables?: UserQueryVariables) {
      return { query: UserDocument, variables: variables }
    }